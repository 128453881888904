.favorite-results {
    width: 90%;
    margin: auto;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.favorite-results .no-items {
    width: 70%;
    margin: auto;
    padding-top: 1.5rem;
    text-align: center;
}

.favorite-results .no-items p {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.favorite-results .no-items a {
    color: var(--tertiary-color);
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    background-color: var(--primary-color);
    padding: 0.3rem 1rem;
    border-radius: 5px;
}

.favorite-results header {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    background-color: var(--secondary-color);
    padding: 0.5rem;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
}

.favorite-results header a {
    text-decoration: none;
}

.favorite-results header button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--tertiary-color);
    cursor: pointer;
    column-gap: 0.3rem;
    padding: 0.2rem 0;
}

.favorite-results header button:hover {
    color: var(--background-color);
}

.favorite-results header hr {
    margin: 0.3rem 0;
}

.favorite-results header h1 {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
}

.favorite-results h2 {
    font-size: 1.2rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .favorite-results {
        width: 75%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .favorite-results {
        width: 75%;
    }

    .favorite-results header {
        padding: 0.5rem 4rem;
    }
}

@media only screen and (min-width: 1281px) {
    .favorite-results {
        width: 65%;
    }

    .favorite-results header {
        padding: 0.5rem 4.5rem;
    }
}