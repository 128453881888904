.results {
    width: 90%;
    margin: auto;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1rem;
}

.results .no-search {
    width: 70%;
    margin: auto;
    padding-top: 1.5rem;
    text-align: center;
}

.results .no-search p {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.results .no-search a {
    color: var(--tertiary-color);
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    background-color: var(--primary-color);
    padding: 0.3rem 1rem;
    border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .results {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .results {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 1281px) {
    .results {
        flex-direction: row;
        flex-wrap: wrap;
    }
}