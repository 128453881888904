.search-results header {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    background-color: var(--secondary-color);
    padding: 0.5rem;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
}

.search-results header a {
    text-decoration: none;
}

.search-results header button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--tertiary-color);
    cursor: pointer;
    column-gap: 0.3rem;
    padding: 0.2rem 0;
}

.search-results header button:hover {
    color: var(--background-color);
}

.search-results header hr {
    margin: 0.3rem 0;
}

.search-results header h1 {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--text-color);
}

.search-results header h1 span {
    font-size: 0.9rem;
    font-weight: 600;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .search-results header {
        padding: 0.5rem 4em;
    }
}

@media only screen and (min-width: 1281px) {
    .search-results header {
        padding: 0.5rem 4.5em;
    }
}