.item {
    color: var(--text-color);
}

.item .swiper {
    width: 100%;
    height: 20rem;
    margin: 1rem auto;
}

.item .swiper-slide {
    background: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.item .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--background-color);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.35));
}

.swiper-pagination-bullet-active {
    background: var(--secondary-color);
}

.item .main-info {
    margin-bottom: 2rem;
}

.item .main-info h1 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0.2rem;
}

.item .main-info .tag {
    font-size: 0.8rem;
    font-weight: 300;
    background-color: var(--primary-color);
    padding: 0.1rem 0.2rem;
    border-radius: 5px;
    color: var(--background-color);
}

.item .main-info .price {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.item .main-info .attributes {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 1rem;
}

.item .main-info .attributes p {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    font-weight: 300;
}

.item .main-info .attributes p svg {
    font-size: 1.2rem;
}

.item>div:not(.main-info) {
    padding: 2rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.item>div:not(.main-info) h2 {
    font-weight: 400;
    margin-bottom: 1rem;
}

.item>div:not(.main-info) p {
    font-weight: 300;
}

.item>div.attributes div.table {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 15em;
}

.item>.attributes .table .attribute {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: space-between;
}

.item>.attributes .table .attribute:nth-child(2n + 1) h3 {
    background-color: rgb(224, 224, 224);
}

.item>.attributes .table .attribute:nth-child(2n + 2) h3 {
    background-color: rgb(248, 248, 248);
}

.item>.attributes .table .attribute:nth-child(2n + 1) span {
    background-color: var(--grey-color);
}

.item>.attributes .table .attribute h3 {
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
}

.item>.attributes .table .attribute span {
    text-align: center;
    padding: 0.5rem;
}