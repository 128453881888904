.result {
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    transition: box-shadow 0.3s ease-out;
    overflow: hidden;
    position: relative;
    padding-bottom: 3rem;
    background-color: var(--background-color);
}

.result:hover {
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
}

.result a {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

.result a .info {
    padding: 1rem;
}

.result a img {
    width: auto;
    max-height: 16.5em;
}

.result a small {
    font-size: 0.8rem;
    font-weight: 300;
}

.result a .price {
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 1.8rem;
}

.result a .title {
    font-size: 1rem;
    font-weight: 400;
    margin: 0.5rem 0;
}

.result a .location {
    font-weight: 300;
    font-size: .85rem;
}

.result a .img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
}

.result a .img .tag {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: var(--primary-color);
    padding: 0.1rem 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--background-color);
    font-size: 0.75rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.result .actions {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.result .actions button {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    background-color: var(--background-color);
    border: none;
    color: var(--primary-color);
}

.result .actions button.selected {
    background-color: rgb(248, 248, 248);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.result .actions button.selected svg:first-child {
    display: none;
}

.result .actions button.selected svg:last-child {
    display: block;
}

.result .actions button:not(.selected) svg:first-child {
    display: block;
}

.result .actions button:not(.selected) svg:last-child {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .result {
        width: calc(50% - (1.5rem / 2));
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .result {
        width: calc(33% - 0.85rem);
    }
}

@media only screen and (min-width: 1281px) {
    .result {
        width: calc(33% - 0.80rem);
    }
}