.modal-container.visible {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container.hidden {
    display: none;
}

.modal-container .modal {
    background-color: var(--background-color);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    padding: 1rem;
    width: 90%;
    color: var(--text-color);
}

.modal-container .modal>div {
    display: flex;
    flex-direction: column;
}

.modal-container .modal input,
.modal-container .modal select {
    height: 2rem;
    background-color: transparent;
    font-family: var(--main-font);
    margin-top: 0.2rem;
}

.modal-container .modal select {
    border: 1px solid var(--tertiary-color);
    border-radius: 5px;
}

.modal-container .modal input {
    border: none;
    outline: none;
    border-bottom: 2px solid var(--grey-color);
}

.modal-container .modal p {
    text-align: center;
    margin: 1rem auto;
}

.modal-container .modal .btn {
    width: 100%;
    margin-top: 2rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .modal-container .modal {
        width: 60%;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .modal-container .modal {
        width: 45%;
    }
}

@media only screen and (min-width: 1281px) {}