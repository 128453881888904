.item-detail {
    margin-top: 4rem;
}

.item-detail .container {
    width: 95%;
    margin: auto;
}

.item-detail header {
    height: 50px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    background-color: var(--secondary-color);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 5em;
}

.item-detail header button {
    font-family: var(--main-font);
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-decoration: underline;
    color: var(--background-color);
    font-weight: 700;
    background-color: transparent;
    border: none;
}

.item-detail header button svg {
    font-size: 1.25rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .item-detail .container {
        width: 80%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .item-detail .container {
        width: 60%;
    }

    .item-detail header {
        padding: 0 4em;
    }
}

@media only screen and (min-width: 1281px) {
    .item-detail .container {
        width: 60%;
    }

    .item-detail header {
        padding: 0 4.5em;
    }
}