.intro h1 {
    text-align: center;
    font-size: 2rem;
    color: var(--tertiary-color);
    margin-top: 2rem;
}

.intro .about {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: auto;
    padding-top: 2rem;
}

.intro .about p {
    margin-bottom: 1rem;
    font-weight: 300;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .intro .about p:last-child {
        margin-bottom: 2.5rem;
    }
}

@media only screen and (min-width: 1281px) {
    .intro .about p:last-child {
        margin-bottom: 2.5rem;
    }
}