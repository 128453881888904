.combobox {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
}

.combobox .input {
    padding: 0.3rem 0.8rem !important;
}

.combobox .listbox.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4.5rem;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    max-height: 8rem;
    overflow-y: scroll;
    z-index: 1;
}

.combobox .listbox.closed {
    display: none;
}

.combobox .listbox .list-item {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--text-color);
    padding: 0.5rem;
    border-bottom: 1px solid var(--grey-color);
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.combobox .listbox .list-item:hover {
    background-color: var(--primary-color);
}

.combobox .listbox .list-item:last-child {
    border-bottom: none;
}