.recent-searches .history .search-button {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.5rem;
    border-radius: 5px;
    border: none;
    background-color: var(--grey-color);
    color: var(--text-color);
    border: 1px solid var(--tertiary-color);
    cursor: pointer;
    transition: filter 0.15s;
    column-gap: 0.5rem;
    box-sizing: border-box;
}

.recent-searches .history .search-button:hover {
    filter: brightness(.9);
}

.recent-searches .history .search-button .delete {
    justify-self: flex-end;
    opacity: 0;
    border: none;
    align-self: stretch;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.recent-searches .history .search-button:hover .delete {
    opacity: 1;
}

.recent-searches .history .search-button svg {
    font-size: 1.25rem;
    color: var(--tertiary-color);
    flex-shrink: 0;
}

.recent-searches .history .search-button span {
    text-align: left;
    font-size: 0.8rem;
    max-width: 22em;
}

.recent-searches .history .search-button span p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.recent-searches .history .search-button span small {
    font-size: 0.65rem;
    font-weight: 300;
}