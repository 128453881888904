.item .main-info .actions-container {
    height: 3rem;
    margin-bottom: 1rem;
}

.item .main-info .actions {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.item .main-info .actions.fixed-top {
    position: fixed;
    top: 60px;
    width: 95%;
    margin: auto;
}

.item .main-info .actions button {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: var(--background-color);
    border: none;
    border-radius: 5px;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.item .main-info .actions.fixed-top button {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.item .main-info .actions button.selected {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.item .main-info .actions button.selected svg:first-child {
    display: none;
}

.item .main-info .actions button.selected svg:last-child {
    display: block;
}

.item .main-info .actions button:not(.selected) svg:first-child {
    display: block;
}

.item .main-info .actions button:not(.selected) svg:last-child {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .item .main-info .actions.fixed-top {
        width: 80%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .item .main-info .actions.fixed-top {
        width: 60%;
    }
}

@media only screen and (min-width: 1281px) {
    .item .main-info .actions.fixed-top {
        width: 60%;
    }
}