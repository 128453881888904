* {
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
}

:root {
  --primary-color: #FFBC0A;
  --secondary-color: #F5B400;
  --tertiary-color: #23395B;
  --background-color: #fbfffb;
  --grey-color: #e8e8e8;
  --text-color: #1C1F21;
  --main-font: 'Nunito', sans-serif;
}